const mount = mod => props => {
  const el = document.createElement('div');
  el.id = 'single-spa-application';
  document.getElementById('root').appendChild(el);

  return mod.mount({ ...props, domElement: el });
};

const unmount = mod => props => {
  return mod.unmount(props).finally(() => {
    document.getElementById('root').innerHTML = '';
  });
};

export const wrapModule = mod => ({
  ...mod.default,
  mount: mount(mod.default),
  unmount: unmount(mod.default),
});
