export const createSpinner = (el, className) => {
  let timer;

  const showSpinner = () => {
    if (timer) {
      return;
    }

    timer = setTimeout(() => {
      el.classList.add(className);
      timer = null;
    }, 200);
  };

  const hideSpinner = () => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    el.classList.remove(className);
  };

  return { showSpinner, hideSpinner };
};
