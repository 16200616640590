import { wrapModule } from './lifecycles';

const locationMatcher = (path, exact) => location => {
  if (exact) {
    return location.pathname === path;
  }
  return location.pathname.startsWith(path);
};

const importer = file => () => System.import(file).then(wrapModule);

export const parseModules = config =>
  Object.keys(config).map(key => {
    const { path = '/', exact = false, js = '', name } = config[key];

    return {
      name,
      path,
      key,
      importFn: importer(js),
      activityFn: locationMatcher(path, exact),
    };
  });
