/* global accModuleConfig */
import { addErrorHandler, registerApplication, start } from 'single-spa';

import { parseModules } from './parse';

export const bootstrap = spinner => {
  // eslint-disable-next-line no-console
  addErrorHandler(console.error);

  if (typeof accModuleConfig === 'undefined') {
    throw new Error('Cannot Find Module Config!');
  }

  parseModules(accModuleConfig).forEach(({ key, importFn, activityFn, name, path }) => {
    const a = document.createElement('a');
    a.className = 'nav-link';
    a.href = path;
    a.innerHTML = name;

    a.addEventListener('click', e => {
      e.preventDefault();
      window.history.pushState({ module: key }, document.title, e.target.href);
    });

    const li = document.createElement('li');
    li.appendChild(a);

    document.getElementById('nav-links').appendChild(li);

    registerApplication(key, importFn, activityFn);
  });

  window.addEventListener('single-spa:before-routing-event', spinner.showSpinner);
  window.addEventListener('single-spa:routing-event', spinner.hideSpinner);

  start();
};
