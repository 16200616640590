import { daznLogo, accountIcon, exitIcon } from './logo';
import * as storage from './storage';

export const renderSidebar = () => {
  const container = document.getElementById('container');
  container.classList.add('with-sidebar');
  const nav = document.createElement('nav');
  const ul = document.createElement('ul');
  ul.id = 'nav-links';
  nav.appendChild(ul);

  container.insertBefore(nav, document.querySelector('main'));
};

export const renderLogin = loginUrl => {
  document.getElementById('root').innerHTML = `<div class="login-page">
  <div class="login-container">
    ${daznLogo('#000')}
    <h1 class="login-title">Application Control Centre</h1>
    <a class="login-button" href="${loginUrl}">
      Sign In With AD
    </a>
  </div>
</div>`;
};

export const renderAccountOptions = () => {
  const head = document.getElementById('head');
  const accountIconWrapper = document.createElement('div');
  const accountOptions = document.createElement('div');
  const accountButton = document.createElement('button');

  accountIconWrapper.className = 'icon-wrapper';
  accountIconWrapper.innerHTML = accountIcon();

  accountOptions.className = 'account-options hidden';

  accountButton.innerHTML = `${exitIcon()}Sign out`;

  accountOptions.appendChild(accountButton);
  head.appendChild(accountIconWrapper);
  head.appendChild(accountOptions);

  accountButton.addEventListener('click', () => {
    storage.removeToken();
    window.location.reload();
  });

  accountIconWrapper.addEventListener('click', () => {
    accountIconWrapper.classList.toggle('dropdown-chevron');
    accountOptions.classList.toggle('hidden');
  });
};
