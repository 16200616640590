import { bootstrap } from './bootstrap';
import { createSpinner } from './spinner';
import * as storage from './storage';
import { isLoggedIn, parseHash, qs, loginQuery } from './auth';
import { renderLogin, renderSidebar, renderAccountOptions } from './elements';

const returnToPrevious = () => window.location.replace(storage.getRedirectPath());

export default (pathname = window.location.pathname) => {
  const spinner = createSpinner(document.querySelector('.spinner'), 'active');
  spinner.showSpinner();

  switch (pathname) {
    case '/auth0': {
      spinner.hideSpinner();
      try {
        const token = parseHash();
        storage.setToken(token);
        returnToPrevious();
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.error(e);
      }
      break;
    }

    case '/login': {
      spinner.hideSpinner();
      if (isLoggedIn()) {
        returnToPrevious();
      } else {
        renderLogin(`https://dazn.eu.auth0.com/authorize?${qs(loginQuery)}`);
      }
      break;
    }

    default: {
      if (isLoggedIn()) {
        renderSidebar();
        renderAccountOptions();
        bootstrap(spinner);
      } else {
        storage.setRedirectPath();
        window.location.assign('/login');
      }
    }
  }
};
