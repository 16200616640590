const storageKeys = {
  TOKEN: 'acc_id_token',
  REDIRECT: 'acc_redirect_to',
};

export const getToken = () => localStorage.getItem(storageKeys.TOKEN);
export const setToken = token => localStorage.setItem(storageKeys.TOKEN, token);
export const removeToken = () => localStorage.removeItem(storageKeys.TOKEN);
export const getRedirectPath = () => sessionStorage.getItem(storageKeys.REDIRECT) || '/';
export const setRedirectPath = (pathname = window.location.pathname) =>
  sessionStorage.setItem(storageKeys.REDIRECT, pathname);
