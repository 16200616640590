export const daznLogo = (fill = '#fff') => {
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path fill="transparent" d="M0 0h512v512H0z" />
  <path
    fill="${fill}"
    fill-rule="evenodd"
    d="M447.9 294.6v153.3H64.1V294.6l30.4-30.4c4.5-4.5 4.5-11.8 0-16.3l-30.4-30.4V64.1h383.8v153.3l-30.4 30.4c-4.5 4.5-4.5 11.8 0 16.3l30.4 30.5zm27.5-258H36.6v194.1L61.9 256l-25.4 25.4v194.1h438.9V281.4L450.1 256l25.4-25.4v-194h-.1z"
    clip-rule="evenodd"
  />
  <path
    fill="${fill}"
    d="M153.1 112.39h36.28q31.86 0 43.9 24.6 2.47 5.13 3.45 10.97.97 5.84.97 15.93v23.9q0 8.85-.97 14.6-.98 5.75-3.45 11.42-10.62 24.6-39.47 24.6H153.1q-4.61 0-6.11-1.33-1.51-1.33-1.51-5.4V119.11q0-4.07 1.51-5.4 1.5-1.32 6.11-1.32zm21.41 24.78v76.46h14.52q10.79 0 14.86-5.49 4.08-5.48 4.08-20v-25.49q0-13.45-4.43-19.47-4.42-6.01-14.51-6.01h-14.52zM340.41 118.4l37.17 112.4q.53 1.24.62 1.77.09.53.09 1.77 0 4.07-4.96 4.07h-16.46q-3.89 0-5.31-1.15-1.42-1.15-3.01-6.11l-6.02-19.29h-42.3l-5.84 19.65q-1.42 4.6-2.75 5.75-1.32 1.15-5.57 1.15h-15.4q-4.96 0-4.96-4.07 0-2.66.71-3.54l37.53-112.4q1.06-3.54 2.74-4.77 1.68-1.24 5.22-1.24h20.53q3.54 0 5.14 1.15 1.59 1.15 2.83 4.86zm-19.29 23.19l-14.16 47.61h28.67l-14.51-47.61zM234.25 281.11v14.16q0 1.42-.35 2.48-.36 1.06-1.6 2.83l-52.21 73.63h49.03q4.07 0 5.31 1.51 1.24 1.5 1.24 6.28v10.62q0 4.78-1.24 6.29-1.24 1.5-5.31 1.5h-77.17q-4.61 0-6.11-1.33-1.51-1.33-1.51-5.4v-14.51q0-2.48 1.95-4.96l51.51-73.63h-44.25q-3.9 0-5.22-1.5-1.33-1.51-1.33-6.29v-10.62q0-4.78 1.33-6.28 1.32-1.5 5.22-1.5h73.1q4.6 0 6.11 1.32 1.5 1.33 1.5 5.4zM338.11 393.86l-35.05-67.08v66.9q0 4.07-1.5 5.4-1.51 1.33-6.11 1.33h-12.04q-4.6 0-6.01-1.33-1.42-1.33-1.42-5.4V281.11q0-4.07 1.5-5.4 1.51-1.32 6.11-1.32h10.97q5.14 0 7.17 1.15 2.04 1.15 4.16 5.39l35.05 66.91v-66.73q0-4.07 1.5-5.4 1.51-1.32 6.11-1.32h11.86q4.6 0 6.11 1.32 1.5 1.33 1.5 5.4v112.57q0 4.07-1.5 5.4-1.51 1.33-6.11 1.33h-10.97q-5.14 0-7.17-1.15-2.04-1.15-4.16-5.4z"
  />
</svg>`;
};

export const accountIcon = () => {
  return `<svg id="accountIcon" viewBox="0 0 100 100" fill="none" color="#9EA2A4">
  <g stroke="currentColor" stroke-width="6">
    <circle cx="50" cy="50" r="40"></circle>
    <circle cx="50" cy="45" r="15"></circle>
    <path d="M20 80s30-40 60 0"></path>
  </g>
</svg>`;
};

export const exitIcon = () => {
  return `<svg viewBox="0 0 100 100" fill="none" color="#0C161C">
  <g stroke="currentColor" stroke-width="8">
    <path d="M60 65 L60 85 L10 85 L10 60 L20 50 L10 40 L10 15 L60 15 L60 35"></path>
    <path d="M32.5 50 L90 50"></path>
    <path d="M72.5 70 L90 50 L72.5 30"></path>
  </g>
</svg>`;
};
