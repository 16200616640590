import * as storage from './storage';
import { getAuth0ClientId } from './config';

/* eslint-disable camelcase */
export const loginQuery = {
  client_id: getAuth0ClientId(),
  response_type: 'id_token',
  redirect_uri: `${window.location.origin}/auth0`,
  scope: 'openid profile email groups',
  connection: 'performgroup-waad',
  nonce: Math.floor(Math.random() * Date.now()),
};
/* eslint-enable camelcase */

export const qs = data =>
  Object.keys(data)
    .map(key => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');

export const isLoggedIn = () => storage.getToken();

export const parseHash = (hash = window.location.hash) => {
  const result = hash.match(/id_token=([^&]+)/i);
  if (result) {
    return result[1];
  }
  throw new Error('Unable to parse hash');
};
